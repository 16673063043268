<script>
// import axios from "axios";

import { ref } from "vue";

// import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

import Layout from "../../../layouts/main";
// import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

/**
 * Add-product component
 */
export default {
  page: {
    title: "Add Product",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  setup() {
    let dropzoneFile = ref("");
    const drop = (e) => {
      dropzoneFile.value = e.dataTransfer.files[0];
    };
    const selectedFile = () => {
      dropzoneFile.value = document.querySelector(".dropzoneFile").files[0];
    };
    return { dropzoneFile, drop, selectedFile, v$: useVuelidate() };
  },
  components: {
    Layout,
    // PageHeader,
  },
  data() {
    return {
      coupon: {},
      coupon_deatiles: {},
      couponTypes: [
        { id: 1, name: "Percantge From Customer Purchases", value: 1 },
        { id: 2, name: "Fixed Amount From Customer Purchases", value: 0 },
      ],
      hasShippings: [
        { id: 1, name: "No", value: 0, name_en: "لا" },
        { id: 2, name: "Yes", value: 1, name_en: "نعم" },
      ],
      isPercintage: false,
    };
  },
  //   validations: {
  //     product: {
  //       name: {
  //         required: helpers.withMessage("Name is required", required),
  //       },
  //       cost_price: {
  //         required: helpers.withMessage("Cost price is required", required),
  //       },
  //       price: {
  //         required: helpers.withMessage("Price is required", required),
  //       },
  //       qty: {
  //         required: helpers.withMessage("Quantity is required", required),
  //       },
  //     },
  //   },
  methods: {
    responseAlert(text, title, icon) {
      this.$swal.fire({
        title: `${title}`,
        text: ` ${text} `,
        icon: `${icon}`,
        toast: true,
        position: "top-start",
        showConfirmButton: false,
        timer: 2000,
      });
    },
    couponEdit() {
      const coupon = this.coupon;
      const details = this.coupon_deatiles;
      this.http
        .put("coupons", this.$route.params.id, {
          coupon,
          details,
        })
        .then((res) => {
          if (res.status) {
            this.coupon = res.data;
            this.coupon_deatiles = res.data.coupon_deatiles[0];
            if (this.$i18n.locale == "ar") {
              this.responseAlert("تم تعديل الكوبون بنجاح", " تم ", "success");
            } else {
              this.responseAlert(
                "Coupon hass been edited successfully",
                "done",
                "success"
              );
            }
          } else {
            if (this.$i18n.locale == "ar") {
              this.responseAlert(
                "حدث خطأ اثناء تعديل الكوبون",
                "خطأ",
                "warning"
              );
            } else {
              this.responseAlert("something went wrong", "error", "warning");
            }
          }
        });
    },
    getCoupon() {
      this.http.get("coupons/" + this.$route.params.id).then((res) => {
        if (res.status) {
          this.coupon = res.data;
          this.coupon_deatiles = res.data.coupon_deatiles[0];
          console.log(this.coupon);
          console.log(this.coupon_deatiles);
        }
      });
    },
    showPercintage(event) {
      this.coupon.percantge = event.target.value;
      if (event.target.value == 1) {
        this.isPercintage = true;
      } else {
        this.isPercintage = false;
      }
    },
  },
  created() {
    this.getCoupon();
  },
};
</script>

<template>
  <Layout>
    <!-- <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="row align-items-center">
        <div class="d-flex align-items-center mb-4">
          <router-link :to="`/coupons`">
            <h4 class="m-0">
              {{ $t("menu.menuitems.marketing.sub.coupons") }}
            </h4>
          </router-link>
          <div class="mx-2">\</div>
          <div>{{ $t("coupons.edit.title") }}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <p class="card-title-desc">{{ $t("products.new.fill") }}</p>
            <form @submit.prevent="couponEdit" ref="sizeForm">
              <div class="row">
                <div class="col">
                  <div class="mb-3 mt-4">
                    <label for="exampleInputEmail1" class="form-label">
                      {{ $t("coupons.name") }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="coupon.name"
                    />
                  </div>
                  <div class="mb-3 mt-4">
                    <label for="exampleInputEmail1" class="form-label">
                      {{ $t("coupons.code") }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="coupon.code"
                    />
                  </div>

                  <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">
                      {{ $t("coupons.type") }}
                    </label>
                    <select
                      @change="showPercintage($event, coupon)"
                      class="form-select"
                      aria-label="Default select example"
                      v-model="coupon_deatiles.percantge"
                    >
                      <option
                        v-for="i in couponTypes"
                        :key="i"
                        :value="i.value"
                      >
                        {{ i.name }}
                      </option>
                    </select>
                  </div>
                  <div>
                    <div class="mb-3">
                      <label for="exampleInputPassword1" class="form-label">
                        {{
                          isPercintage
                            ? $t("coupons.percant")
                            : $t("coupons.amount")
                        }}
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="coupon_deatiles.percantge_value"
                      />
                    </div>
                    <div class="mb-3">
                      <label for="exampleInputPassword1" class="form-label">
                        {{ $t("coupons.descount_amount") }}
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="coupon_deatiles.percantge_price"
                      />
                    </div>
                  </div>
                  <div class="mb-3 mt-4">
                    <label for="exampleInputEmail1" class="form-label">
                      {{ $t("coupons.end_date") }}
                    </label>
                    <input
                      type="date"
                      class="form-control"
                      v-model="coupon_deatiles.end_date"
                    />
                  </div>
                </div>
                <div class="col">
                  <div class="mb-3 mt-4">
                    <label for="exampleInputEmail1" class="form-label">
                      {{ $t("coupons.use_count") }}
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="coupon_deatiles.use"
                    />
                  </div>
                  <div class="mb-3 mt-4">
                    <label for="exampleInputEmail1" class="form-label">
                      {{ $t("coupons.customer_count") }}
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="coupon_deatiles.use_per_custmor"
                    />
                  </div>
                  <div class="mb-3 mt-4">
                    <label for="exampleInputEmail1" class="form-label">
                      {{ $t("coupons.min_pruchers_number") }}
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="coupon_deatiles.min_pruchers_number"
                    />
                  </div>

                  <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">
                      {{ $t("coupons.execlude_products") }}
                    </label>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      v-model="coupon_deatiles.execlude_products"
                    >
                      <option
                        :selected="coupon_deatiles.execlude_products"
                        value="1"
                      >
                        {{ $t("coupons.yes") }}
                      </option>
                      <option
                        :selected="!coupon_deatiles.execlude_products"
                        value="0"
                      >
                        {{ $t("coupons.no") }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="mt-4">
                <button
                  type="submit"
                  class="btn btn-primary"
                  style="margin-inline-end: 8px"
                >
                  {{ $t("popups.save") }}
                </button>
                <router-link to="/coupons">
                  <button class="btn btn-secondary">
                    {{ $t("popups.cancel") }}
                  </button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
